import { atom, selectorFamily } from 'recoil'
import { type LocationInfo } from 'types'

type NewLocationInfo = LocationInfo | null

const selectedLocationInfosState = atom<Map<string, NewLocationInfo>>({
  key: 'selectedLocationInfosState',
  default: new Map(),
})

const selectedLocationInfoState = selectorFamily<NewLocationInfo, string>({
  key: 'selectedLocationInfoState',
  get:
    (formName: string) =>
    ({ get }) => {
      const allLocations = get(selectedLocationInfosState)
      return allLocations.get(formName) ?? null
    },
  set:
    (formName: string) =>
    ({ get, set }, newValue) => {
      const allLocations = get(selectedLocationInfosState)
      const newMap = new Map(allLocations)
      newMap.set(formName, newValue as NewLocationInfo)
      set(selectedLocationInfosState, newMap)
    },
})

const hasNewAddressAllState = atom<Set<string>>({
  key: 'hasNewAddressAllState',
  default: new Set(),
})

const hasNewAddressState = selectorFamily<boolean, string>({
  key: 'hasNewAddressState',
  get:
    (formName: string) =>
    ({ get }) => {
      const all = get(hasNewAddressAllState)
      return all.has(formName)
    },
  set:
    (formName: string) =>
    ({ get, set }, newValue) => {
      const all = get(hasNewAddressAllState)
      const newSet = new Set(all)
      if (newValue) {
        newSet.add(formName)
      } else {
        newSet.delete(formName)
      }

      set(hasNewAddressAllState, newSet)
    },
})

const currentZoomState = atom<number>({
  key: 'currentZoomState',
  default: 10,
})

export { selectedLocationInfoState, hasNewAddressState, currentZoomState }
