import { atom, selector } from 'recoil'
import { type GridSortModel, type GridScrollParams } from '@mui/x-data-grid'
import {
  CASE_PERIODS_DEFAULT,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
  CaseListColumn,
  type CaseStatus,
  CurrentCaseListView,
  MAP_DEFAULT_REGION,
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
  type Period,
  type Shareability,
  MAP_DEFAULT_LANGUAGE,
} from 'components/case/caseConstants'
import { type CaseInfo } from 'components/case/caseTypes'
import { portalSettingState } from 'state/portalSettingStates'

const selectedCaseShareabilitiesState = atom<Shareability[]>({
  key: 'selectedCaseShareabilitiesState',
  default: CASE_SHAREABILITIES_DEFAULT,
})

const selectedCaseStatusesState = atom<CaseStatus[]>({
  key: 'selectedCaseStatusesState',
  default: CASE_STATUSES_DEFAULT,
})

const selectedCasePeriodState = atom<Period>({
  key: 'selectedCasePeriodState',
  default: CASE_PERIODS_DEFAULT,
})

const casesDataState = atom<CaseInfo[]>({
  key: 'casesDataState',
  default: [],
})

const sortModelState = atom<GridSortModel>({
  key: 'sortModelState',
  default: [
    {
      field: CaseListColumn.UPDATED,
      sort: 'desc',
    },
  ],
})

const offsetState = atom<number>({
  key: 'offsetState',
  default: 0,
})

const lastScrollPositionState = atom<GridScrollParams | null>({
  key: 'lastScrollPositionState',
  default: null,
})

const currentCaseListViewState = atom<CurrentCaseListView>({
  key: 'currentCaseListViewState',
  default: CurrentCaseListView.LIST,
})

const columnVisibilityState = atom<Record<CaseListColumn, boolean>>({
  key: 'columnVisibilityState',
  default: {
    [CaseListColumn.IMAGE]: true,
    [CaseListColumn.ADDRESS_AND_TITLE]: true,
    [CaseListColumn.SHAREABILITY]: true,
    [CaseListColumn.STATUS]: true,
    [CaseListColumn.CREATED_BY]: true,
    [CaseListColumn.UPDATED]: true,
    [CaseListColumn.CREATED]: false,
  },
})

const keywordState = atom<string>({
  key: 'keywordState',
  default: '',
})

const selectedMapCenterState = atom<google.maps.LatLngLiteral | null>({
  key: 'selectedMapCenterState',
  default: null,
})

const currentMapCenterState = selector<google.maps.LatLngLiteral>({
  key: 'currentMapCenterState',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)
    const selectedMapCenter = get(selectedMapCenterState)

    return (
      selectedMapCenter ||
      portalSetting?.mapConfiguration?.centre ||
      MAP_DEFAULT_CENTER
    )
  },
  set: ({ set }, newValue) => {
    set(selectedMapCenterState, newValue)
  },
})

const selectedMapZoomState = atom<number | null>({
  key: 'selectedMapZoomState',
  default: null,
})

const currentMapZoomState = selector<number>({
  key: 'currentMapZoomState',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)
    const selectedMapZoom = get(selectedMapZoomState)

    return (
      selectedMapZoom ||
      portalSetting?.mapConfiguration?.zoomLevel ||
      MAP_DEFAULT_ZOOM
    )
  },
  set: ({ set }, newValue) => {
    set(selectedMapZoomState, newValue)
  },
})

const currentMapRegionState = selector<string>({
  key: 'currentMapRegionState',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)
    return portalSetting?.mapConfiguration?.region || MAP_DEFAULT_REGION
  },
})

const currentMapLanguageState = selector<string>({
  key: 'currentMapLanguageState',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)
    return portalSetting?.defaultLanguage.toLowerCase() || MAP_DEFAULT_LANGUAGE
  },
})

const currentMapCaseListScrollTopState = atom<number>({
  key: 'currentMapCaseListScrollTopState',
  default: 0,
})

const currentMapTypeState = atom<string>({
  key: 'currentMapTypeState',
  default: 'roadmap',
})

export {
  selectedCaseShareabilitiesState,
  selectedCaseStatusesState,
  selectedCasePeriodState,
  casesDataState,
  offsetState,
  lastScrollPositionState,
  currentCaseListViewState,
  columnVisibilityState,
  sortModelState,
  keywordState,
  currentMapCenterState,
  currentMapZoomState,
  currentMapRegionState,
  currentMapLanguageState,
  currentMapCaseListScrollTopState,
  selectedMapCenterState,
  selectedMapZoomState,
  currentMapTypeState,
}
