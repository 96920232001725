import { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useRecoilState } from 'recoil'
import { type Loader as GMapLoader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { debounce } from '@mui/material/utils'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import UpdateIcon from '@mui/icons-material/Update'
import PublicIcon from '@mui/icons-material/Public'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import CaseMarker from 'components/case/CaseMarker'
import { type PublicCaseInfo } from './publicCaseTypes'
import { parseComponentToElement } from 'utils/domUtils'
import { type CaseStatus } from 'components/case/caseConstants'
import { SmallInfoText, SubSubHeader } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import CaseStatusBadge from 'components/case/CaseStatusBadge'
import ResourceInlineSlider from 'components/resource/ResourceInlineSlider'
import PublicCommentList from 'components/publicCase/PublicCommentList'
import { nameInitials } from 'utils/stringUtils'
import CategoryTagList from 'components/category/CategoryTagList'
import { getThumbnailUrl } from 'utils/fileUtils'
import { AnonymousIcon } from 'components/icons/Icons'
import { getGoogleMapsLoader } from 'components/form/mapLoader'
import { currentMapTypeState } from 'state/caseListStates'

type CaseListMapMobileProps = {
  region?: string
  language?: string
  zoom?: number
  center?: google.maps.LatLngLiteral
  cases: PublicCaseInfo[]
}

const CaseDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    width: 100%;
    border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    height: 80%;
  }
`

const SharedWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.text.primary};
  border: none;

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 16px;
  }
  align-items: center;
  border-bottom-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  font-size: 12px;
  min-width: 110px;
  font-weight: 500;
`

const TitleWrapper = styled('h2')`
  font-size: 22px;
  font-weight: 500;
`

const AddressWrapper = styled(Stack)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 6px;
`

const CommentCounter = styled(Box)`
  padding: 2px 6px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: rgba(233, 236, 252, 1);
  font-size: 0.8rem;
`

const UserCard = styled(CardHeader)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const REGION = 'FI'
const DEFAULT_ZOOM = 12
const DEFAULT_CENTER = { lat: 60.1695, lng: 24.9354 }
const DEFAULT_LANGUAGE = 'fi'

const PublicCaseListMapMobile: React.FC<CaseListMapMobileProps> = ({
  region = REGION,
  language = DEFAULT_LANGUAGE,
  zoom = DEFAULT_ZOOM,
  center = DEFAULT_CENTER,
  cases,
}) => {
  const { formatMessage } = useIntl()
  const gmapLoaderRef = useRef<GMapLoader | null>(null)
  const mapElementRef = useRef<HTMLElement>(null)
  const mapServiceRef = useRef<google.maps.Map | null>(null)
  const markerClusterRef = useRef<MarkerClusterer | null>(null)
  const selectedMarkerRef =
    useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null)
  const [isCaseDrawerOpen, setIsCaseDrawerOpen] = useState(false)
  const { formatDate } = usePortalSetting()
  const [currentMapType, setCurrentMapType] =
    useRecoilState(currentMapTypeState)

  const loadMarkers = async (cases: PublicCaseInfo[]): Promise<void> => {
    if (mapServiceRef.current && gmapLoaderRef.current) {
      const { AdvancedMarkerElement } =
        await gmapLoaderRef.current.importLibrary('marker')

      const results = cases.filter(
        (caseInfo) =>
          caseInfo.location?.position &&
          mapServiceRef.current
            ?.getBounds()
            ?.contains(caseInfo.location?.position),
      )

      const markers = results.map((caseInfo) => {
        const marker = new AdvancedMarkerElement({
          position: caseInfo.location?.position,
          content: parseComponentToElement(
            <CaseMarker status={caseInfo.status} />,
          ),
        })

        marker.addListener('click', () => {
          if (selectedMarkerRef.current) {
            const container = selectedMarkerRef.current.content
              ?.firstChild as HTMLDivElement
            const status = container.getAttribute('status') as CaseStatus
            selectedMarkerRef.current.content = parseComponentToElement(
              <CaseMarker status={status} selected={false} />,
            )
          }

          selectedMarkerRef.current = marker
          marker.content = parseComponentToElement(
            <CaseMarker status={caseInfo.status} selected={true} />,
          )

          setSelectedCaseId(caseInfo.id)
          setIsCaseDrawerOpen(true)
        })

        return marker
      })

      markerClusterRef.current?.clearMarkers()
      markerClusterRef.current?.addMarkers(markers)
    }
  }

  const debounceLoadMarkers = debounce(async (cases: PublicCaseInfo[]) => {
    await loadMarkers(cases)
  }, 400)

  const init = async (cases: PublicCaseInfo[]): Promise<void> => {
    if (mapElementRef.current) {
      gmapLoaderRef.current = getGoogleMapsLoader({
        region,
        language,
      })

      const { Map } = await gmapLoaderRef.current.importLibrary('maps')

      mapServiceRef.current = new Map(mapElementRef.current, {
        center,
        zoom,
        mapId: process.env.REACT_APP_GMAP_ID,
        mapTypeId: currentMapType,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControl: false,
        streetViewControl: false,
        streetView: null,
      })

      mapServiceRef.current.addListener(
        'zoom_changed',
        async (): Promise<void> => {
          await debounceLoadMarkers(cases)
        },
      )

      mapServiceRef.current.addListener('dragend', async (): Promise<void> => {
        await debounceLoadMarkers(cases)
      })

      markerClusterRef.current = new MarkerClusterer({
        markers: [],
        map: mapServiceRef.current,
      })

      mapServiceRef.current.addListener(
        'tilesloaded',
        async (): Promise<void> => {
          await loadMarkers(cases)
        },
      )

      mapServiceRef.current.addListener('maptypeid_changed', () => {
        const currentMapTypeId = mapServiceRef.current?.getMapTypeId()
        if (currentMapTypeId) {
          setCurrentMapType(currentMapTypeId)
          localStorage.setItem('mapTypeId', currentMapTypeId)
        }
      })
    }
  }

  useEffect(() => {
    void init(cases)
    const analytics = getAnalytics()
    logEvent(analytics, 'web_public_cases_loaded_mobile')
  }, [cases])

  const handleCaseDrawerOpen = (): void => {
    setIsCaseDrawerOpen(true)
    setSelectedCaseId(null)
  }

  const handleCaseDrawerClose = (): void => {
    setIsCaseDrawerOpen(false)
    setSelectedCaseId(null)
  }

  const selectedCase = useMemo((): PublicCaseInfo | null => {
    if (!selectedCaseId) {
      return null
    }

    const result = cases.find((publicCase) => publicCase.id === selectedCaseId)

    return result ?? null
  }, [selectedCaseId, cases])

  return (
    <>
      <Box
        ref={mapElementRef}
        flexGrow={1}
        height="100%"
        borderRadius={1}
      ></Box>

      <CaseDrawer
        anchor="bottom"
        open={isCaseDrawerOpen}
        onClose={handleCaseDrawerClose}
        onOpen={handleCaseDrawerOpen}
      >
        {selectedCase && (
          <Stack>
            <Stack direction="row" width={'100%'} paddingX={1}>
              <SmallInfoText flexGrow={1} paddingTop={0.5}>
                <UpdateIcon sx={{ fontSize: 14 }} />
                {formatDate(selectedCase.updated)}
              </SmallInfoText>

              <Stack direction="row" spacing={1}>
                <SharedWrapper
                  direction="row"
                  spacing={1}
                  paddingX={1}
                  paddingY={1}
                >
                  <PublicIcon />
                  <Box>
                    {formatMessage({
                      id: 'case_detail.label.shared',
                    })}
                  </Box>
                </SharedWrapper>

                <CaseStatusBadge status={selectedCase.status} />
              </Stack>
            </Stack>

            <Stack paddingX={1} spacing={1} paddingY={1}>
              <ResourceInlineSlider
                resources={selectedCase.resources}
                width={'100%'}
                height={'calc(70vw)'}
                showZoomInButton={false}
                imageSize="contain"
              />

              <TitleWrapper>
                {selectedCase.title || selectedCase.description}
              </TitleWrapper>

              <Stack spacing={1} paddingBottom={2}>
                <SmallInfoText flexGrow={1} paddingBottom={2}>
                  {formatMessage(
                    {
                      id: 'case_detail.label.report_created',
                    },
                    {
                      date: formatDate(selectedCase.created),
                    },
                  )}
                </SmallInfoText>

                <AddressWrapper direction="row">
                  <LocationOnIcon fontSize="small" color="secondary" />
                  {selectedCase.location?.address}
                </AddressWrapper>

                <Box overflow="hidden" paddingLeft={2.5}>
                  <CategoryTagList category={selectedCase.category} />
                </Box>
              </Stack>

              <Stack spacing={2}>
                <Typography variant="body2">
                  {selectedCase.description}
                </Typography>

                <SmallInfoText flexGrow={1} marginBottom={1}>
                  {formatMessage(
                    {
                      id: 'public_case.noticed',
                    },
                    {
                      date: formatDate(selectedCase.occurred),
                    },
                  )}
                </SmallInfoText>

                {selectedCase.reporter && (
                  <UserCard
                    avatar={
                      <Avatar
                        src={getThumbnailUrl(selectedCase.reporter.avatarUrl)}
                        sx={{ width: 30, height: 30 }}
                        alt={selectedCase.reporter.name}
                      >
                        {nameInitials(selectedCase.reporter.name)}
                      </Avatar>
                    }
                    title={
                      <Stack>
                        <SmallInfoText>
                          {formatMessage({
                            id: 'case_list.cases.header.reported_by',
                          })}
                        </SmallInfoText>
                        <SenderName>{selectedCase.reporter.name}</SenderName>
                      </Stack>
                    }
                  />
                )}

                {!selectedCase.reporter && (
                  <UserCard
                    avatar={
                      <Avatar
                        sx={{ width: 30, height: 30 }}
                        alt={formatMessage({
                          id: 'case_detail.label.anonymous',
                        })}
                      >
                        <AnonymousIcon />
                      </Avatar>
                    }
                    title={
                      <Stack>
                        <SmallInfoText>
                          {formatMessage({
                            id: 'case_list.cases.header.reported_by',
                          })}
                        </SmallInfoText>
                        <SenderName>
                          {formatMessage({
                            id: 'case_detail.label.anonymous',
                          })}
                        </SenderName>
                      </Stack>
                    }
                  />
                )}

                <Divider />

                <Stack direction="row" spacing={1} alignItems="center">
                  <SubSubHeader>
                    {formatMessage({ id: 'case_detail.tab.label.comments' })}
                  </SubSubHeader>

                  {selectedCase.comments.length > 0 && (
                    <CommentCounter>
                      {selectedCase.comments.length}
                    </CommentCounter>
                  )}
                </Stack>

                <PublicCommentList comments={selectedCase.comments} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </CaseDrawer>
    </>
  )
}

export default PublicCaseListMapMobile
